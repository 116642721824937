<template>
    <div class="portrait column">
        <div class="paragraph-header row ml-0 mr-0">
            <span class=""> 
                {{biographyBanner}}
            </span>
        </div>
        <div class="img-box">
          <img :src="imageSrc" alt="">
          <audio-toggle v-if="audio" :key="audio.id" :white-background="true" :audio="audio"/>
        </div>
        <disclaimer v-if="portrait.disclaimer && portrait.disclaimer[locale]" :disclaimer="portrait.disclaimer[locale]"/>
        <div class="portrait-footer row" v-if="portrait.caption">
            <span v-tooltip="portrait.caption[locale] || ''">{{portrait.caption[locale]}}</span>
        </div>
    </div>    
</template>
<script>
import Disclaimer from '@/common/Disclaimer.vue'
import AudioToggle from "@/common/AudioToggle.vue";

export default {
    name: 'BiographyPortrait', 
    props:['portrait'],
    components:{
        Disclaimer,
        AudioToggle
    },
    computed:{
        imageSrc(){
            return this.getImageUrl(this.portrait.name || null)
        },
        biographyBanner(){
            let bannerText = this.$t('biography');
            return bannerText.charAt(0).toUpperCase() + bannerText.slice(1);
        },
        audio(){
            return this.portrait.audio;
        }
    }
}
</script>
<style lang="less" scoped>
.paragraph-header{
    min-height: 56px;
    width: 100%;
    background-color: #0A1554;
    span{
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
        text-align: center;
        align-self: center;
        justify-self: center;
        font-size: 19.64px;
        color: #FFFFFF;
    }
    span:nth-child(1){
        margin: auto;
    }
}
.portrait{
	position: relative;
	height: auto;
  min-height: 520px;
  max-width: 374px;
  width: 374px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.2);
	.portrait-header{
    position: relative;
    text-transform: capitalize;
    max-height: 56px;
    width: 374px;
    background-color: #0A1554;
      img{
        height: auto;
        width: 100%;
        max-height: 56px;
        max-width: 374px;
      }
        span{
          color: #fff;
          font-size: 19.64px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 24px;
        }
  }
  .portrait-footer{
    margin-left: 0 !important;
    min-height: 48px;
    width: 374px;
    background-color: #EAEBFF;
      span{
        margin: auto;
        color: #3A4DFD;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 16px;
        text-transform: uppercase;
      }
  }
  // img{
  //   height: 416px;
  //   width: 100%;
  // }
  .audio-toggle{
    position: absolute;
    top: 442px;
    left: 344px;
  }
}
.main-biography {
  .portrait{
    max-width: 100%;
    width: 100%;
    margin: 0 0 0 0;
    min-height: auto;
    .paragraph-header{
      img{
        height: auto;
        width: auto;
        max-width: 100%;
      }
    }
    .img-box {
      text-align: center;
      position: relative;
      min-height: 33px;

      img{
        height: auto;
        width: auto;
        max-width: 100%;
      }
      .audio-toggle {
        position: absolute;
        right: 5px;
        bottom: 5px;
        top: auto;
        left: auto;
        z-index: 1;
      }
    }
    .portrait-footer {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      span{
        white-space: inherit;
      }
    }
  }
}
</style>



