<template>
  <div class="main-biography" :style="backgroundColor">
    <div class="container">
      <div class="row">
        <div class="biography" v-if="biography">
          <div class="biography-container top-column mt-5">
            <div class="row biography-top-margin">
              <div class="col-md-5 col-sm-5 col-xs-5">
                <div ref="leftcol">
                <BiographypPortrait :portrait="portrait" />
                </div>
              </div>
              <div class="col-md-7 col-sm-7 col-xs-7">
                <BiographyMainCard :mainCard="mainCard" :content="mainCard" :style="{maxHeight: maxheight + 'px'}"/>
              </div>
            </div>
          </div>
          <div class="biography-container bottom-column">
            <div class="row">
              <div class="col-md-5 col-sm-5 col-xs-5">
                <complementaryText :audio="audio" :content="complementaryText" />
              </div>
              <div class="col-md-7 col-sm-7 col-xs-7">
                <div class="column">
                  <template v-if="hasMainIlustration">
                    <div class="img-container">
                      <img
                        :src="mainIlustrationImage"
                        class="main-ilustration"
                        v-on:load="getImageWidth"
                      />
                    </div>
                    <div class="caption-row">

                      <audio-toggle
                        v-if="mainIlustrationAudioImage"
                        :key="mainIlustrationAudioImage.id"
                        :white-background="true"
                        :audio="mainIlustrationAudioImage"
                      />
                      <div
                        class="img-caption pt-1"
                        :class="{'AudioImage': mainIlustrationAudioImage}"
                        v-if="mainIlustrationShowCaptionImage"
                        :style="imageStyles"
                      >
                        {{ mainIlustrationCaptionImage }}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <video-player
                      v-if="videoUrl"
                      :id="'biography-Video:' + biography.id"
                      :url="videoUrl"
                      :width="624"
                      :height="360"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap-lesson">
            <component
              v-for="component in components"
              :is="component.paragraph.type"
              :key="component.paragraph.id"
              :content="component.paragraph"
            />
          </div>
          <div class="col-12 d-flex prev-button mb-30px">
            <div class="row">
              <button class="btn round primary back" @click="$router.go(-1)">
                {{ $t("back") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/common/VideoPlayer.vue";
import BiographypPortrait from "@/components/Biography/BiographyPortrait.vue";
import BiographyMainCard from "@/components/Biography/BiographyMainCard.vue";
import Paragraph from "@/common/Paragraph.vue";
import BiographyService from "@/services/BiographyService.js";
import LessonParagraph from "@/components/Lesson/LessonParagraph.vue";
import LessonCard from "@/components/Lesson/LessonCard.vue";
import SectionTitle from "@/components/Lesson/SectionTitle.vue";
import EssentialQuestion from "@/common/EssentialQuestion.vue";
import LessonTextContent from "@/components/Lesson/LessonTextContent.vue";
import VideoContent from "@/components/Lesson/VideoContent.vue";
import InteractiveMap from "@/components/Lesson/InteractiveMap.vue";
import OnlyImages from "@/components/Lesson/OnlyImages.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import GlobalConnections from "@/components/Lesson/GlobalConnections.vue";
import InteractiveLesson from "@/components/Lesson/InteractiveLesson.vue";

export default {
  name: "Biography",
  components: {
    BiographypPortrait,
    BiographyMainCard,
    complementaryText: Paragraph,
    BiographyService,
    paragraph: LessonParagraph,
    card: LessonCard,
    "section-title": SectionTitle,
    "text-content": LessonTextContent,
    "only-images": OnlyImages,
    VideoContent,
    EssentialQuestion,
    VideoPlayer,
    InteractiveMap,
    InteractiveLesson,
    AudioToggle,
    GlobalConnections,
  },
  data(){
    return{
      maxheight: null
    }
  },
  methods: {
    loadBiography() {
      this.$store.dispatch("loadBiography", this.$route.params.biographyId);
      this.$store.dispatch("loadCurrentUnit", this.$route.params.unitId);
    },
    getLeftColumnHeight(){
      // var fullHeight = this.$refs.leftcol.clientHeight;
      // this.maxheight = fullHeight
      // console.log("sad", fullHeight)
    }
  },
  created() {
    this.loadBiography();
  },
  computed: {
    unit() {
      return this.$store.state.currentUnit;
    },
    biography() {
      return this.$store.state.currentBiography;
    },
    components() {
      return this.biography.components || [];
    },
    portrait() {
      return this.biography.portrait || {};
    },
    mainCard() {
      return this.biography.mainCard || {};
    },
    mainIlustration() {
      return this.biography.media || {};
    },
    hasMainIlustration() {
      return !this.mainIlustration.image || !this.mainIlustration.image.name
        ? false
        : true;
    },
    mainIlustrationImage() {
      return this.hasMainIlustration
        ? this.getImageUrl(this.mainIlustration.image.name)
        : "";
    },
    mainIlustrationAudioImage() {
      return this.mainIlustration.audioImage;
    },
    mainIlustrationCaptionImage() {
      return this.hasMainIlustration ? this.mainIlustration.captionImage : "";
    },
    mainIlustrationShowCaptionImage() {
      return (
        this.mainIlustration && this.mainIlustration.captionImage.trim() != ""
      );
    },
    videoUrl() {
      return this.mainIlustration.videoUrl;
    },
    complementaryText() {
      return this.biography.complementaryText;
    },
    audio() {
      return this.biography.audioComplementaryText;
    },
    backgroundColor() {
      return {
        backgroundColor: this.biography.backgroundColor || "",
        opacity: this.biography.opacity / 100 || 1,
      };
    },
  },
  watch: {
    $route: function () {
      this.loadBiography();
    },
  },
  mounted() {
    this.startTrackingPage({ sectionName: "Biography" });
    this.$store.commit("setAnalyzedManagementSection", "Biography");
    // setInterval(()=>{
    //   var fullHeight = this.$refs.leftcol.clientHeight;
    //   this.maxheight = fullHeight
    // },2000)
    window.scrollTo(0, 0);
  },
  updated(){
    setTimeout(()=>{
            var fullHeight = this.$refs.leftcol.clientHeight;
            this.maxheight = fullHeight
      },1000)
  },
  beforeDestroy() {
    this.$store.commit("setAnalyzedManagementSection", "");
    this.stopTrackingPage({ sectionName: "Biography" });
  },
};
</script>

<style lang="less">
.biography {
  margin: 0 auto;
  margin-top: 104px;
  width: 1280px;
  max-width: 1280px;
  flex-direction: column;
  position: relative;

  .biography-background {
    z-index: -4;
    position: fixed;
    top: 0;
    min-height: 100%;
    width: 100%;
  }

  .lesson {
    margin-top: 0;
    padding-bottom: 20px;
  }
  .lesson-container {
    height: auto;
    flex-direction: column;
  }
  .biography-container {
    margin-bottom: 30px;
    justify-content: center;
    // section {
    //   // margin-top: 16px;
    // }
    section:nth-child(2) {
      margin-left: 16px;
      width: 622px;
      > .row {
        margin-top: 32px;
      }
      .main-ilustration,
      .video-player {
        margin: auto;
        width: auto;
        max-width: 624px;
        height: auto;
        max-height: 360px;
        margin-top: 32px;
      }
      .img-container {
        position: relative;
        margin: auto;
        .audio-toggle {
          position: absolute;
          right: 16px;
          bottom: 16px;
        }
      }
      .img-caption {
        margin: auto;
      }
    }

    & /deep/ .paragraph > pre {
      width: 374px;
    }
    .caption-row {
      padding-top: 10px;
      display: flex;
      .img-caption {
        font-family: "Roboto", sans-serif;
        width: calc(100% - 24px);
        margin: 0 !important;
      }
    }
    > .audio-toggle {
      position: absolute;
      top: 584px;
      left: 110px;

      background-color: #ffffff;
      border-radius: 50%;
    }
  }
}
.main-biography {
  font-family: "Roboto", sans-serif;
  .biography {
    margin-top: 86px;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    position: relative;
    .wrap-lesson {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      height: inherit;
      padding-bottom: 0;
    }
  }
  .biography-container {
    &.top-column {
      position: relative;
      justify-content: left;
      section:nth-child(2) {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        margin-left: 0;
        width: 745px;
        height: 100%;
        .biography-paragraph {
          min-height: auto;
          height: 100%;
          overflow-y: auto;
          padding-bottom: 20px;
        }
      }
    }
    &.bottom-column {
      margin-top: 0;
      margin-bottom: 30px;
      .paragraph {
        max-width: 100%;
        width: 100%;
        p {
          width: 100%;
          margin: 0;
        }
      }
      
        .img-container {
          margin: 0;
          .main-ilustration,
          .video-player {
            margin: auto;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: auto;
            margin-top: 0;
          }
        }
        .caption-row {
          i{
            margin-right: 8px;
          }
          .img-caption {
            font-family: "Roboto", sans-serif;
            max-width: 100% !important;
          }
        }
      
    }
    .paragraph-content {
      width: auto;
    }
  }
  .interactive-lesson {
    .new-point {
      margin-bottom: 40px;
    }
  }
}
.d-flex {
  display: flex;
  justify-content: end;
}
.prev-button {
  width: 100%;
  margin-bottom: 30px;
  justify-content: flex-end;
}
.global-connection-modal-box {
  .global_connection_left {
    .global_connection_left_desc {
      margin-right: 0;
    }
    p{
      p{
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  .global_connection_right {
    p{
      p{
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
.biography-top-margin{
  margin-top: 50px !important;
}
</style>