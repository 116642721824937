<template>
    <div class="biography-paragraph column">
        <div class="paragraph-header row ml-0 mr-0">
            <span>{{name}}</span>
            <span>{{period}}</span>
            <AudioToggle v-if="mainCard.audio" :key="mainCard.audio.id"
                :white-background="true"
                :audio="mainCard.audio"/>
        </div>
        <span class="paragraph-content" v-if="textContent && textContent.length > 0" @click="GoToLink" v-html="textContent"></span>
        <span class="centered" v-else> {{$t('noBiographyContentLegend') }}</span>

        <modal v-if="showModal" :text-button="$t('done')"
            :click-handler="hideVocabularyBox" 
            :clickOutSideModalHandler="hideVocabularyBox"
            :showCancelButton="showModalCancelButton"
            :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
    </div>    
</template>
<script>
import EssentialQuestion from '@/common/EssentialQuestion.vue'
import AudioToggle from '@/common/AudioToggle.vue'
import BiographyMainCardMixin from './BiographyMainCardMixin.js'
export default {
    name: 'BiographyMainCard',
    mixins:[BiographyMainCardMixin],
    components:{
        EssentialQuestion, 
        AudioToggle
    },
    props: ['mainCard'], 
    computed:{
        name(){
            return this.mainCard.name
        },
        period(){
            return this.mainCard.period
        },
        essentialQuestion(){
            return this.mainCard.essentialQuestion     
        }
    }
}
</script>
<style lang="less" scoped>
.biography-paragraph{
    min-height: 100%;
    height: auto;
    position: relative;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.2);
    background-color: #fff;
}

.paragraph-header{
     min-height: 56px;
     width: 100%;
     background-color: #0A1554;
     span{
        color: #FFFFFF;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
        text-align: center;
        align-self: center;
        justify-self: center;
    }
     span:nth-child(1){
        margin-left: auto;
    }
     span:nth-child(2){
        margin-right: auto;
        font-size: 14.64px;
        margin-left: 16px;
    }
    .audio-toggle{
      align-self: center;
      margin-right: 24px;
      height: 24px;
      width: 24px;
    }
}

.paragraph-content{
        height: auto;
        width: 622px;
        color: #000;
        background-color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-align: justify;
        overflow-y: auto;
        margin:15px 0 15px 22px;
        box-sizing: border-box;
        white-space: pre-line;
        padding-right: 22px;
        p{
            margin-top: 0 !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        .clickable-link{
            display: inline;
            cursor: pointer;
        }
        .clickable-link:hover{
            text-decoration: underline;
        }
 }


</style>
<style lang="less">
.paragraph-content{
    p{
        margin-top: 0 !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
</style>



