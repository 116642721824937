var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portrait column" },
    [
      _c("div", { staticClass: "paragraph-header row ml-0 mr-0" }, [
        _c("span", {}, [
          _vm._v(" \n            " + _vm._s(_vm.biographyBanner) + "\n        ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "img-box" },
        [
          _c("img", { attrs: { src: _vm.imageSrc, alt: "" } }),
          _vm.audio
            ? _c("audio-toggle", {
                key: _vm.audio.id,
                attrs: { "white-background": true, audio: _vm.audio }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.portrait.disclaimer && _vm.portrait.disclaimer[_vm.locale]
        ? _c("disclaimer", {
            attrs: { disclaimer: _vm.portrait.disclaimer[_vm.locale] }
          })
        : _vm._e(),
      _vm.portrait.caption
        ? _c("div", { staticClass: "portrait-footer row" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.portrait.caption[_vm.locale] || "",
                    expression: "portrait.caption[locale] || ''"
                  }
                ]
              },
              [_vm._v(_vm._s(_vm.portrait.caption[_vm.locale]))]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }