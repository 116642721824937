var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-biography", style: _vm.backgroundColor },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm.biography
            ? _c("div", { staticClass: "biography" }, [
                _c(
                  "div",
                  { staticClass: "biography-container top-column mt-5" },
                  [
                    _c("div", { staticClass: "row biography-top-margin" }, [
                      _c("div", { staticClass: "col-md-5 col-sm-5 col-xs-5" }, [
                        _c(
                          "div",
                          { ref: "leftcol" },
                          [
                            _c("BiographypPortrait", {
                              attrs: { portrait: _vm.portrait }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-7 col-sm-7 col-xs-7" },
                        [
                          _c("BiographyMainCard", {
                            style: { maxHeight: _vm.maxheight + "px" },
                            attrs: {
                              mainCard: _vm.mainCard,
                              content: _vm.mainCard
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "biography-container bottom-column" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-5 col-sm-5 col-xs-5" },
                        [
                          _c("complementaryText", {
                            attrs: {
                              audio: _vm.audio,
                              content: _vm.complementaryText
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-7 col-sm-7 col-xs-7" }, [
                        _c(
                          "div",
                          { staticClass: "column" },
                          [
                            _vm.hasMainIlustration
                              ? [
                                  _c("div", { staticClass: "img-container" }, [
                                    _c("img", {
                                      staticClass: "main-ilustration",
                                      attrs: { src: _vm.mainIlustrationImage },
                                      on: { load: _vm.getImageWidth }
                                    })
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "caption-row" },
                                    [
                                      _vm.mainIlustrationAudioImage
                                        ? _c("audio-toggle", {
                                            key:
                                              _vm.mainIlustrationAudioImage.id,
                                            attrs: {
                                              "white-background": true,
                                              audio:
                                                _vm.mainIlustrationAudioImage
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.mainIlustrationShowCaptionImage
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "img-caption pt-1",
                                              class: {
                                                AudioImage:
                                                  _vm.mainIlustrationAudioImage
                                              },
                                              style: _vm.imageStyles
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.mainIlustrationCaptionImage
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  _vm.videoUrl
                                    ? _c("video-player", {
                                        attrs: {
                                          id:
                                            "biography-Video:" +
                                            _vm.biography.id,
                                          url: _vm.videoUrl,
                                          width: 624,
                                          height: 360
                                        }
                                      })
                                    : _vm._e()
                                ]
                          ],
                          2
                        )
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "wrap-lesson" },
                  _vm._l(_vm.components, function(component) {
                    return _c(component.paragraph.type, {
                      key: component.paragraph.id,
                      tag: "component",
                      attrs: { content: component.paragraph }
                    })
                  })
                ),
                _c(
                  "div",
                  { staticClass: "col-12 d-flex prev-button mb-30px" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn round primary back",
                          on: {
                            click: function($event) {
                              _vm.$router.go(-1)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("back")) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }