var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "biography-paragraph column" },
    [
      _c(
        "div",
        { staticClass: "paragraph-header row ml-0 mr-0" },
        [
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _c("span", [_vm._v(_vm._s(_vm.period))]),
          _vm.mainCard.audio
            ? _c("AudioToggle", {
                key: _vm.mainCard.audio.id,
                attrs: { "white-background": true, audio: _vm.mainCard.audio }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.textContent && _vm.textContent.length > 0
        ? _c("span", {
            staticClass: "paragraph-content",
            domProps: { innerHTML: _vm._s(_vm.textContent) },
            on: { click: _vm.GoToLink }
          })
        : _c("span", { staticClass: "centered" }, [
            _vm._v(" " + _vm._s(_vm.$t("noBiographyContentLegend")))
          ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "text-button": _vm.$t("done"),
                "click-handler": _vm.hideVocabularyBox,
                clickOutSideModalHandler: _vm.hideVocabularyBox,
                showCancelButton: _vm.showModalCancelButton,
                modalType: _vm.modalType
              }
            },
            [_c("vocabulary-box", { attrs: { vocabulary: _vm.selectedWord } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }