<template>
    <div class="paragraph row ml-0 mr-0">
        <div class="content" :class="{'audio-content': audio}">
            <p>{{content}}</p>
        </div>
        <AudioToggle v-if="audio" :audio="audio"/>
    </div>    
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'
export default {
    name: 'Paragraph', 
    components:{
        AudioToggle
    },
    props:['content', 'audio'], 
}
</script>
<style lang="less" scoped>
    .paragraph
    {
        p{    
            width: 324px;
            color: #000;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            letter-spacing: 0.4px;
            line-height: 22px;
            padding: 8px;
        }
        .audio-toggle{
            margin-left: auto;
            margin-right: 8px;
            margin-top: 0;
        }
        .content {
            width: 100%;
            p{
                padding: 0;
            }
            &.audio-content {
                width: 93%;
            }
        }
    }
</style>
