var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paragraph row ml-0 mr-0" },
    [
      _c(
        "div",
        { staticClass: "content", class: { "audio-content": _vm.audio } },
        [_c("p", [_vm._v(_vm._s(_vm.content))])]
      ),
      _vm.audio ? _c("AudioToggle", { attrs: { audio: _vm.audio } }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }